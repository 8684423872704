import * as React from "react"
import Seo from "../components/seo"
import { RichText } from 'prismic-reactjs'
import { Link, graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import Copyright from "../components/copyright"
import { GatsbyImage } from "gatsby-plugin-image"

const ProductSpecialty = ({data}) => {
  const entry = data.prismicSpecialtyTapes
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={entry.url} />
      <Header />
      <div className="w-full pt-6 pb-6 border-b-2 border-grey">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="font-verdana text-md lg:text-lg">
            <Link to="/tapes/" className="border-b-2 border-black">Tapes</Link> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg> 
            <Link to="/tapes/specialty/" className="border-b-2 border-black">Specialty</Link> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg> 
            {entry.data.heading.text}
          </div>
        </div>
      </div>
      <div className="w-full pt-12 pb-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex gap-12 items-center justify-between">
            <div className="w-full sm:w-6/12">
              <div className="font-ubuntu font-bold text-red text-4xl sm:text-5xl leading-tight mb-12">
                <h1>{entry.data.heading.text}</h1>
              </div>
              <div className="font-ubuntu prose prose-xl leading-normal">
                {RichText.render(entry.data.text.richText)}
              </div>
              <div className="mt-12 inline-block">
                <div className="font-ubuntu text-2xl text-red border-2 border-red py-2 px-4 inline-block xl:inline mr-6 mb-6 xl:mb-0">
                  <Link to="/contact/">Make enquiry <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                </div>
                {/* If brochure exists, show download brochure button */}
                {entry.data.brochure.url && (
                <div className="font-ubuntu text-2xl text-red border-2 border-red py-2 px-4 inline-block xl:inline">
                  <Link to={entry.data.brochure.url}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" /></svg> Download brochure</Link>
                </div>
                )}
              </div>
            </div>
            <div className="w-full sm:w-6/12 mt-12 sm:mt-0">
              <GatsbyImage image={entry.data.image.gatsbyImageData} alt={entry.data.heading.text} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-12 border-t-2 border-grey">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex gap-12 justify-between">
            <div className="w-full sm:w-6/12">
              {entry.data.applications.text && (
              <div className="pb-6">
                <h3 className="font-ubuntu text-red text-2xl mb-6">Applications:</h3>
                <div className="font-ubuntu prose prose-xl leading-normal">
                  {RichText.render(entry.data.applications.richText)}
                </div>
              </div>
              )}
              {entry.data.properties.text && (
              <div className="pb-6">
                <h3 className="font-ubuntu text-red text-2xl mb-6">Properties:</h3>
                <div className="font-ubuntu prose prose-xl leading-normal">
                  {RichText.render(entry.data.properties.richText)}
                </div>
              </div>
              )}
              {entry.data.thicknesses.text && (
              <div className="pb-6">
                <h3 className="font-ubuntu text-red text-2xl mb-6">Thicknesses:</h3>
                <div className="font-ubuntu prose prose-xl leading-normal">
                  {RichText.render(entry.data.thicknesses.richText)}
                </div>
              </div>
              )}
              {entry.data.storage.text && (
              <div className="pb-6">
                <h3 className="font-ubuntu text-red text-2xl mb-6">Storage:</h3>
                <div className="font-ubuntu prose prose-xl leading-normal">
                  {RichText.render(entry.data.storage.richText)}
                </div>
              </div>
              )}
              {entry.data.shelf_life.text && (
              <div className="pb-6">
                <h3 className="font-ubuntu text-red text-2xl mb-6">Shelf life:</h3>
                <div className="font-ubuntu prose prose-xl leading-normal">
                  {RichText.render(entry.data.shelf_life.richText)}
                </div>
              </div>
              )}
              {entry.data.tech_specs.text && (
              <div className="pb-6">
                <h3 className="font-ubuntu text-red text-2xl mb-6">Technical specifications:</h3>
                <div className="font-ubuntu prose prose-xl leading-normal">
                  {RichText.render(entry.data.tech_specs.richText)}
                </div>
              </div>
              )}
              {/* If data sheet exists, show download button */}
              {entry.data.data_sheet.url && (
              <div className="font-ubuntu text-2xl text-red border-2 border-red py-2 px-4 mb-12 mt-3 inline-block">
                <Link to={entry.data.data_sheet.url}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" /></svg> Download data sheet</Link>
              </div>
              )}
            </div>
            <div className="w-full sm:w-6/12">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Copyright />
    </>
  )
}

export const query = graphql`
query($handle: String) {
  prismicSpecialtyTapes(uid: {eq: $handle}) {
    url
    data {
      text {
        richText
      }
      page_title
      meta_description
      heading {
        text
      }
      image {
        gatsbyImageData(width: 1024)
      }
      tech_specs {
        richText
        text
      }
      applications {
        richText
        text
      }
      thicknesses {
        richText
        text
      }
      storage {
        richText
        text
      }
      shelf_life {
        richText
        text
      }
      properties {
        richText
        text
      }
      data_sheet {
        url
      }
      brochure {
        url
      }
    }
  }
}
`

export default ProductSpecialty